import debounce from "lodash.debounce";

import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  Chip,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import RightIcon from "@mui/icons-material/ChevronRight";

import Title from "./Title";
import SearchBox from "./SearchBox";

import { search } from "../../../api/search";
import useAxios from "../../../hooks/useAxios";

const Search = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [result, setResult] = useState({});
  const [page, setPage] = useState(1);
  const [response, call, loading] = useAxios({ ...search });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    const params = {
      query: searchQuery,
      limit: 10,
      page: page,
    };
    if (searchQuery && searchQuery.length) debouncedFetchData({ params });
  }, [searchQuery, page]);

  useEffect(() => {
    if (response && response.status) {
      setResult(response.data);
    }
  }, [response]);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleSearchChange = (searchString) => {
    setSearchQuery(searchString);
    setPage(1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Search`} gutterBottom />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item>
          <Stack sx={{ mb: "5px" }}>
            <SearchBox
              searchValue={searchQuery}
              clearSearch={clearSearch}
              submit={handleSearchChange}
            />
          </Stack>
        </Grid>
      </Grid>

      {result && result?.length
        ? result.map((val) => (
            <Stack sx={{ mb: "5px" }} key={val.table_id}>
              <Accordion>
                <AccordionDetails>
                  <div>
                    <Chip size="small" label={val.content} />
                  </div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: val.text_eng }}
                  ></div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: val.text_orig }}
                  ></div>
                  <div>
                    <Chip size="small" label={val.distance} />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Stack>
          ))
        : null}
      {result && result?.length && (
        <>
          <IconButton
            disabled={page === 1}
            onClick={() => setPage((page) => page - 1)}
            sx={{ p: "10px" }}
            aria-label="left"
          >
            <LeftIcon />
          </IconButton>
          <IconButton
            onClick={() => setPage((page) => page + 1)}
            sx={{ p: "10px" }}
            aria-label="right"
          >
            <RightIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default Search;
