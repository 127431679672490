import React from 'react';

import Typography from '@mui/material/Typography';

const Title = ({ variant, content, ...rest }) => {
  return (
    <Typography variant={variant} gutterBottom component="div" {...rest}>
      {content}
    </Typography>
  );
};

export default Title;
