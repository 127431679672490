import { useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LoadingContext } from "../context/index";
import { initialLoading, loadingReducer } from "../reducer/loading";

import Home from "./views/home/Home";
import LoadingBox from "./views/LoadingBox";
import Search from "./views/search/Search";

function App() {
  const [isLoading, loadingDispatch] = useReducer(
    loadingReducer,
    initialLoading
  );
  return (
    <BrowserRouter>
      <LoadingContext.Provider value={[isLoading, loadingDispatch]}>
        <LoadingBox />
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<Search />} />
          </Route>
        </Routes>
      </LoadingContext.Provider>
    </BrowserRouter>
  );
}

export default App;
