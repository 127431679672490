const initialLoading = {
  isLoading: false,
};

const setLoadingInfo = (state, payload) => {
  return {
    ...state,
    isLoading: payload.isLoading,
  };
};

const loadingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return setLoadingInfo(state, action.payload);

    default:
      throw new Error();
  }
};

export { initialLoading, loadingReducer };
