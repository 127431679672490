import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBox({ searchValue, submit, clearSearch }) {
  const [val, setVal] = useState(searchValue);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  const handleClear = () => {
    setVal("");
  };

  const handleClick = () => {
    submit(val);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit(val);
    }
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={handleChange}
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleEnter}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={val}
      />
      <IconButton
        disabled={!val}
        onClick={handleClick}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        disabled={!val}
        onClick={handleClear}
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <CancelIcon />
      </IconButton>
    </Paper>
  );
}
