import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";

import TopNavBar from "./TopNavBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

const drawerWidth = 240;
function Dashboard(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [appConfig, setAppConfig] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          {appConfig ? (
            <TopNavBar handleDrawerToggle={handleDrawerToggle} />
          ) : (
            <Skeleton variant="rectangular" width="100%">
              <Typography variant="h6" noWrap component="div">
                .
              </Typography>
            </Skeleton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Skeleton variant="rectangular" height="100%"></Skeleton>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: "calc(100vh - 40px)",
        }}
      >
        <Toolbar />
        {appConfig ? (
          <Outlet />
        ) : (
          <Skeleton
            variant="rectangular"
            height="calc(100vh - 120px)"
          ></Skeleton>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard;
