import { useContext } from 'react';

import { LoadingContext } from '../context/index';

const useLoading = () => {
  const [loading, loadingDispatch] = useContext(LoadingContext);
  const { isLoading } = loading;

  const setIsLoading = (data) => {
    loadingDispatch({
      type: 'SET_LOADING',
      payload: data,
    });
  };

  return { isLoading, setIsLoading };
};

export default useLoading;
