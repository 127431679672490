import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Button, ListItemButton, Stack } from "@mui/material";

const TopNavBar = ({ handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const nav = useNavigate();

  const listItemTop = [
    {
      text: "Search",
      to: "/",
    },
  ];

  const handleClick = (e, index) => {
    setOpenIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (index) => {
    setOpenIndex(null);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mr: 2, display: { xs: "none", sm: "flex" } }}
      >
        {listItemTop.map((list, index) =>
          list.subMenu && list.subMenu.length ? (
            <div key={`Button${list.text}`}>
              <Button
                color="inherit"
                key={`Button${list.text}`}
                id={`basic-button-${index}`}
                aria-controls={index === openIndex ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={index === openIndex ? "true" : undefined}
                onClick={(e) => handleClick(e, index)}
              >
                {list.text}
              </Button>
              <Menu
                id={`basic-menu-${index}`}
                key={`Menu${list.text}`}
                open={index === openIndex}
                onClose={() => handleClose(index)}
                anchorEl={anchorEl}
              >
                {list.subMenu.map((eachSubMenu) => (
                  <ListItemButton
                    button
                    component={Link}
                    to={`${list.to}/${eachSubMenu.to}`}
                    key={`${list.to}/${eachSubMenu.to}`}
                  >
                    {eachSubMenu.text}
                  </ListItemButton>
                ))}
              </Menu>
            </div>
          ) : (
            <Button
              color="inherit"
              key={list.text}
              to={list.to}
              onClick={list?.onclick ? list?.onclick : () => nav(list.to)}
            >
              {list.text}
            </Button>
          )
        )}
      </Stack>
    </>
  );
};

export default TopNavBar;
